<template>
  <v-select
    label="text"
    :options="sedes"
    v-model="selected"
    :reduce="sede => mode === 'object' ? sede : sede.ID"
    @search="debounceSearch"
    @input="setChange"
    :class="classObject"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input class="vs__search" v-bind="attributes" v-on="events" />
    </template>
  </v-select>
</template>

<script>
//import Axios from "axios";
import ServiceFactory from "./../../services/ServiceFactory";
import {debounce} from 'lodash';
import VSelect from 'vue-select';
const SedeService = ServiceFactory.get("sede");
import sedeService from "./../../services/sedeService";

export default {
  props: ["value", "mode", "validator"],
  components : {VSelect},
  data() {
    return {
      data_sources_sede: {
        sede: "/api/sede/data-source-sede",
      },
      sedes: [],
      selected: this.value,
      search: "",
    };
  },
  created() {
    this.debounceSearch = debounce(this.buscarSede, 300);
  },
  watch: {
    value: {
      handler: function (newValue) {
        if (newValue !== null || newValue !== undefined) {

          if (this.mode === "object") {

            let find = this.sedes.find(x=> x.ID === newValue.ID);

            if(find !== undefined && find !== null){
                this.selected = newValue;
                return;
            }

            SedeService.getSedeByCode(newValue.ID)
              .then((response) => {
                let sede = {
                  ID: response.data.ID,
                  text: `${response.data.CODIGO} ${response.data.NOMBRE_SEDE}`,
                };
                this.sedes.push(sede);
                this.selected = sede;
              })
              .catch((error) => {
                console.error(error);
              });
          } else {

            let find = this.sedes.find(x=> x.ID === newValue);

            if(find !== undefined && find !== null){
                this.selected = newValue;
                return;
            }

            SedeService.getSedeByCode(newValue)
              .then((response) => {
                let sede = {
                  ID: response.data.ID,
                  text: `${response.data.CODIGO} ${response.data.NOMBRE_SEDE}`,
                };
                this.sedes.push(sede);
                this.selected = newValue;
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    async buscarSede(search, loading) {
      if (search === "") {
        return;
      }
      try {
        loading(true);
        const response = await sedeService.dataSource(`?s=${escape(search)}`);
        this.sedes = response.data.results;
        loading(false);
      } catch (error) {
        loading(false);
        alert("Ocurrio un error, por favor intente nuevamente");
      }
      
     
      /* Axios.get(`${this.data_sources_sede.sede}?s=${escape(search)}`)
        .then((response) => {
          this.sedes = response.data.results;
          loading(false);
        })
        .catch(() => {
          
        }); */
    },
    setChange(value) {
      this.$emit("input", value);
      this.$emit("update-value", value);
    },

  },
  computed: {
      classObject: function () {

          if (this.validator === undefined || this.validator === null) {
              return "";
          }

          return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
      }
  },
};
</script>
